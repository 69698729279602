.tab {
  text-transform: capitalize !important;
  font-weight: 400;
  font-size: 13px;
  min-height: auto;
  min-width: auto;
  font-family: "Inter", "Helvetica", "Arial", sans-serif;
}

.tabs {
  width: 100%;
  min-height: auto !important;
  @apply border-b border-cardBorder;
  &.MuiTabs-indicator {
    background-color: red;
  }
}
