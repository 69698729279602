.customField {
  position: relative;
  // min-width: 180px !important;
  input {
    font-size: 14px !important;
  }
  svg {
    font-size: 16px;
    right: 8px !important;
    position: relative;
  }
}
