.label {
    .labelText {
        margin-bottom: 8px;
        display: inline-block;
    }
}

.textSmall {
    font-size: 14px !important;
    padding: 6px 12px;
}
.inputGroup {
    width: 100%;
}

