.customSearchField {
  position: relative;
  min-width: 220px !important;
  input {
    font-size: 14px !important;
  }
  svg {
    font-size: 18px;
    right: 0px !important;
    position: relative;
  }
}
.customField {
  position: relative;
  // min-width: 220px !important;
  width: 100%;
  input {
    font-size: 14px !important;
  }
}
