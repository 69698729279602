@tailwind base;
@tailwind components;
@tailwind utilities;

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input,
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  height: 1.5rem !important;
  font-size: 12px;
  padding: 5px !important;
}
.MuiOutlinedInput-root {
  height: 2rem !important;
  font-size: 12px;
  padding: 5px !important;
}

.MuiTablePagination-root {
  min-height: 30px !important;
}
.MuiTablePagination-toolbar {
  min-height: 30px !important;
}
.primaryButton {
  color: white !important;
  background-color: #333333 !important;
  border: 1px solid #333333 !important;
}
.secondaryButton {
  // color: rgb(3, 0, 0) !important;
  color: #333333 !important;
  background-color: transparent !important;
  border: 1px solid #333333 !important;
}

.css-1wc848c-MuiFormHelperText-root {
  margin-left: 0px !important;
}

.MuiTableCell-head:last-child {
  border-right: 1px solid #dcdcdc !important;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  border-radius: 0px;
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 100px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  background-color: #c4c4c4;
}
.MuiInputBase-formControl {
  fieldset {
    border-width: 1px !important;
  }
}
.customSearchField {
  .MuiInputBase-formControl.Mui-focused {
    fieldset {
      border-color: #333333 !important;
    }
  }
}
.mediumDialogBox
  .MuiDialog-container.MuiDialog-scrollPaper
  .MuiDialog-paperScrollPaper {
  min-width: 508px;
  max-width: 600px;
  margin: 0;
  border-radius: 12px;
  box-shadow: -6px 0px 8px rgb(0 0 0 / 10%);
}

.Mui-disabled {
  background-color: #fafafa;
}
.customTabs {
  .MuiTabs-flexContainer {
    button {
      padding: 12px !important;
    }
  }
  .MuiTabs-indicator {
    background-color: #333333 !important;
  }
}
.tabContent {
  min-height: calc(100vh - 174px);
}
.Mui-focused {
  fieldset {
    border-color: #333333 !important;
    border-width: 1px !important;
  }
}
li.MuiBreadcrumbs-separator {
  font-size: 22px;
  line-height: 20px;
}
.text-red {
  color: rgb(239, 68, 68) !important;
}
.icontButton {
  border: 1px solid #caceda !important;
}

button.primaryButton.Mui-disabled {
  background: #333 !important;
  opacity: 0.7;
}

.favouriteBlock .customCardContainer {
  border-radius: 0 0 0.5rem 0.5rem !important;
  border: 0 !important;
  height: auto !important;
}
.css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root {
  font-size: 14px !important;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  font-size: 12px !important;
}
table.MuiTable-root tbody td .flex svg {
  width: 16px;
  min-width: 16px;
  height: 16px;
  margin-left: 0 !important;
  margin-right: 0.2rem;
}

table.MuiTable-root tbody td .flex {
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
}
