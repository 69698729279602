@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Inter", sans-serif;
  }
  body,
  body * {
    font-family: "Inter", sans-serif !important;
  }
}

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Inter", sans-serif;
}
.dialogContent {
  @apply flex-1;
}
.dialogFooter {
  @apply flex gap-3 p-3 border-t border-formBorder;
}


.labelText {
  @apply block text-xs font-semibold  mb-2 capitalize text-[#555555];
}
.detailtext {
  @apply text-[#222222];
}
.fixedInputClass {
  @apply rounded appearance-none text-sm relative block w-full h-8 px-3 py-2 border border-formBorder  focus:z-10;
}
.customCardContainer {
  @apply border border-cardBorder;
}
.customTabs .MuiTabs-flexContainer button {
  @apply text-primary;
}
.customTabs .MuiTabs-flexContainer .Mui-selected {
  @apply font-semibold;
}

.autoComplete .MuiInputBase-formControl input {
  @apply text-sm;
}